@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, 'background');

  mat-sidenav {
    width: 260px;
  }

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
  }

  mat-sidenav-content[tunnelSpecificities] {
    background-color: mat.get-color-from-palette($background, app-bar);
  }
}

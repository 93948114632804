@use 'sass:map';
@use '@angular/material' as mat;
@use '../typography' as typography;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, 'background');
  $foreground: map.get($config, 'foreground');

  .mat-table {
    border-radius: 4px;

    tr.bf-hoverable:hover {
      * {
        color: var(--primary);
      }
    }

    tr.even-row {
      background-color: var(--table-even-row);
      td.mat-cell.sticky:last-of-type {
        background: var(--table-even-row);
      }
    }
    
    tr.odd-row {
      background-color: var(--table-odd-row);
      td.mat-cell.sticky:last-of-type {
        background: var(--table-odd-row);
      }
    }
    
    td.mat-cell.sticky:last-of-type {
      background: var(--background-card);
    }
    thead {
      background-color: mat.get-color-from-palette($background, card);
    }
    thead.bf-table-header {
      background-color: #334BD2;
      color: #FFFFFF;
      .mat-header-cell {
        font-weight: bold;
      }

      .sortable .sort mat-icon{
        color: #FFFFFF;
      }
    }
    .mat-cell, .mat-header-cell {
      padding: 0 16px;
      border-bottom: solid 1px mat.get-color-from-palette($foreground, divider);
    }
    td.mat-cell {
      font-size: 16px;
    }
    td.mat-cell:first-of-type {
      border-left: solid 1px mat.get-color-from-palette($foreground, divider);
    }

    td.mat-cell:last-of-type {
      border-right: solid 1px mat.get-color-from-palette($foreground, divider);
    }

    .mat-header-cell {
      border-top: solid 1px mat.get-color-from-palette($foreground, divider);
      &:first-of-type {
        border-radius: 4px 0 0 0;
        border-left: solid 1px mat.get-color-from-palette($foreground, divider);
      }
      &:last-of-type {
        border-right: solid 1px mat.get-color-from-palette($foreground, divider);
        border-radius: 0 4px 0 0;
      }
    }
  }

  // Style all paginator that are direcly after a mat table
  .mat-table + .mat-paginator {
    border: solid 1px mat.get-color-from-palette($foreground, divider);
    border-top: none;
    border-radius: 0 0 10px 10px;
  }
}

// TYPOGRAPHY
@mixin typography($theme) {
  .mat-table {
    .mat-header-cell {
      @include mat.typography-level(typography.$bf-typography, 'caption');
    }
  }
}
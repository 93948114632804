@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);

  .mat-mdc-dialog-container {
    position: relative;
    border-radius: 8px;
    .mat-mdc-dialog-surface {
      padding: 24px;
    }
    .mat-dialog-content {
      overflow: auto;
      margin: 0 -24px;
      padding: 0 24px;
    }
    .mat-mdc-dialog-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

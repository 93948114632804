@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map-get($config, foreground);

  .mat-tab-label:hover {
    .mat-icon {
      path, g, polygon {
        fill: mat.get-color-from-palette($foreground, base);
      }
    }
  }

  *:not(.mat-mdc-tab-group-inverted-header) > .mat-mdc-tab-header {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }
  .mat-mdc-tab-group-inverted-header > .mat-mdc-tab-header {
    border-top: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .bf-tabs {
    .mat-mdc-tab-header {
      border-bottom: none;
      padding: 24px;
      justify-content: center;
      --mdc-secondary-navigation-tab-container-height: 66px;

      .mat-mdc-tab-label-container {
        flex-grow: 0;
        padding: 1px;
      }
    }

    .mdc-tab--active {
      --mat-tab-header-active-hover-label-text-color: #ffffff;
      --mat-tab-header-active-focus-label-text-color: #ffffff;
      background-color: var(--primary);
      border: 1px solid var(--primary);
      .mdc-tab__text-label {
        color: #ffffff;
      }
    }
    .mat-mdc-tab:not(.mdc-tab--active) {
      color: #ffffff;
      border: 1px solid var(--primary);
      .mdc-tab__text-label {
        color: var(--primary);
      }
    }
    
  }
}

@mixin typography($theme) {
  .mat-mdc-tab-link .mdc-tab__text-label,
  .mat-mdc-tab .mdc-tab__text-label {
    --mat-tab-header-inactive-label-text-color: var(--foreground-text);
    --mat-tab-header-inactive-hover-label-text-color: var(--foreground-text);
  }

  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: 0.5px;
  }
}
@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');
  $foreground: map.get($config, 'foreground');
  $color-font: map.get($config, 'font');

  .mat-headline-1 {
    color: map.get($color-font, 'headline-1');
  }
  .mat-headline-2 {
    color: map.get($color-font, 'headline-2');
  }
  .mat-headline-3 {
    color: map.get($color-font, 'headline-3');
    margin: unset;
  }
  .mat-headline-4 {
    color: map.get($color-font, 'headline-4');
  }
  h1,
  .mat-h1,
  .mat-headline-5 {
    color: map.get($color-font, 'h1');
  }
  h2,
  .mat-h2,
  .mat-headline-6 {
    color: map.get($color-font, 'h2');
  }
  h3,
  .mat-h3,
  .mat-subtitle-1 {
    color: map.get($color-font, 'h3');
  }
  h4,
  .mat-h4,
  .mat-body-1 {
    color: map.get($color-font, 'h4');
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    color: map.get($color-font, 'h5')
  }
  h6 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: map.get($color-font, 'h6')
  }
  p,
  bf-tag,
  .mat-body-2,
  .mat-body {
    color: map.get($color-font, 'mat-body-2');
  }
  .mat-subtitle-2,
  .mat-body-strong {
    color: map.get($color-font, 'mat-subtitle-2');
  }
  .mat-caption,
  .mat-small {
    color: map.get($color-font, 'mat-caption');
  }
  input, td {
    color: map.get($foreground, 'text');
  }

  .text-primary {
    color: mat.get-color-from-palette($primary);
  }

  .text-accent {
    color: mat.get-color-from-palette($accent);
  }

  .text-warn {
    color: mat.get-color-from-palette($warn);
  }

  .text-disabled {
    color: map.get($foreground, 'disabled');
  }

  .mat-toolbar h6 {
    margin: 0;
  }
}

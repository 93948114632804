/* You can add global styles to this file, and also import other style files */
@use './surface';

:root {
  --layout-margin: 24px;
  --toolbarHeight: 80px;
}

* {
  box-sizing: border-box;
}

html {
  height: 100vh;
}

body {
  margin: 0;
}

a {
  text-decoration-line: none;
}

p, span, td {
  a[href]:not([href^="mailto"]):not([href^="assets"]) {
    text-decoration: underline;
  }
}

a:not(.mat-mdc-button-base):not(.mat-mdc-menu-item) {
  &[href^="assets"], &[href^="mailto"] {
    font-weight: bold;
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul.grid {
  --min-column: 250px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-column), 1fr));
  grid-gap: 24px;
  margin: 24px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Apply space of 8px between span & icon everywhere except for mat-icon-buttons
*:not(.mdc-icon-button):not(.mat-mdc-fab):not(.mat-mdc-mini-fab) > {
  .mat-icon + span,
  span:not(.mat-mdc-button-persistent-ripple) + .mat-icon {
    margin-left: 8px;
  }
}

// Apply space of 4px between span & spinner inside a or button
button, a {
  .mat-spinner {
    display: inline;
  }
  span + .mat-spinner, 
  .mat-spinner + span {
    margin-left: 4px;
  }
}

main {
  height: 100%;
}

/* Leaflet WorldMap default pane */
.leaflet-pane {
  z-index: 1;
}

.upload-widget {
  display: flex;
  flex-direction: column-reverse;
}

.app-bar {
  margin-left: 24px;

  input {
    height: calc(var(--toolbarHeight) - 24px);
    width: 50vw;
    padding: 0 16px;
    background-color: transparent;
    border: 1px solid var(--foreground-divider);
    border-radius: 4px;
    font: inherit;

    &:hover,
    &:focus {
      border-color: var(--primary);
    }

    &:focus {
      outline: none;
    }
  }
}

.surface {
  padding: 24px;
  @include surface.surface;
}

a.surface {
  &:hover {
    filter: drop-shadow(0px 5px 5px var(--card-shadow));
  }
}

body {
  .intercom-lightweight-app, .intercom-namespace {
    z-index: 999; // It needs to be above everything except the cdk-overlay, which has a z-index: 1000
  }
}

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, 'foreground');

  .mat-expansion-panel {
    @include mat.overridable-elevation(0);
    // HEADER
    .mat-expansion-panel-header {
      padding: 0 16px;
      .mat-expansion-panel-header-title * {
        margin: 0;  // Force heading to have no margin
      }
    }

    // BODY
    .mat-expansion-panel-body {
      padding: 0;
      padding-bottom: 16px;
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }
  }

  .breakdown .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0px;
  }

  .mat-accordion .mat-expansion-panel {
    border-bottom: solid 1px mat.get-color-from-palette($foreground, divider);
    &:last-of-type {
      border-bottom: none;
    }
  }



}

@mixin typography($theme) {
  $config: mat.get-typography-config($theme);
  
  .mat-expansion-panel-header-title {
    font: {
      size: mat.font-size($config, 'subtitle-2');
      weight: mat.font-weight($config, 'button');
    }
  }

  .mat-expansion-panel-content,
  .mat-expansion-panel-content .mdc-form-field {
    @include mat.typography-level($config, subtitle-2);
  }
}

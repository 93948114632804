@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, 'background');
  $warn: map.get($config, 'warn');

  .tunnel-layout {
    background-color: mat.get-color-from-palette($background, hover);
    padding: 10px;
    .mat-toolbar {
      box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
      border-radius: 4px 4px 0 0;
    }
    .mat-sidenav-container {
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
      border-radius: 0 0 4px 4px;
    }

    .invalid-fields {
      color: mat.get-color-from-palette($warn, 500);
    }

  }

}

@use '@angular/material' as mat;
@use 'sass:map';
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900');

/* Definition
mat.define-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
)*/

:root {
  --headline-4-size: 48px;
  --headline-4-lineHeight: 58px;
  --headline-3-size: 60px;
  --headline-3-lineHeight: 64px;
}

@media (max-width: 600px) {
  :root {
    --headline-4-size: 46px;
    --headline-4-lineHeight: 53px;
    --headline-3-size: 48px;
    --headline-3-lineHeight: 53px;
  }
}

$bf-typography: map.merge(mat.define-typography-config(
    $font-family: 'Montserrat, Helvetica Neue, sans-serif',
    $headline-1: mat.define-typography-level(96px, 113px, 500, $letter-spacing: -1.5px),
    $headline-2: mat.define-typography-level(80px, 100px, 700, $letter-spacing: -0.5px),
    $headline-3: mat.define-typography-level(var(--headline-3-size), var(--headline-3-lineHeight), 800),
    $headline-4: mat.define-typography-level(var(--headline-4-size), var(--headline-4-lineHeight), 700, $letter-spacing: -0.15px),
    $headline-5: mat.define-typography-level(36px, 52px, 800, $letter-spacing: -0.5px),
    $headline-6: mat.define-typography-level(26px, 36px, 700, $letter-spacing: -1px),
    $subtitle-1: mat.define-typography-level(20px, 28px, 700),
    $body-1: mat.define-typography-level(16px, 26px, 500, $letter-spacing: 4px),
    $subtitle-2: mat.define-typography-level(16px, 26px, 400, $letter-spacing: 0.5px),
    $body-2: mat.define-typography-level(18px, 28px, 400, $letter-spacing: 0.5px),
    $caption: mat.define-typography-level(13px, 21px, 400),
    $button: mat.define-typography-level(16px, 24px, 600, $letter-spacing: 1.25px),
  ),
  (
    input: mat.define-typography-level(inherit, 1.925, 400)
  )
);

pre {
  font-family: mat.font-family($bf-typography);
  font-size: mat.font-size($bf-typography, 'body-2'); // Get size of body-2
}

// TODO : Create a builder for typography with two polices
@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-toolbar {
    height: 80px;
    box-shadow: none;
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    background-color: mat.get-color-from-palette($background, app-bar);
  }
}

// TYPOGRAPHY
@mixin typography($config) {

  .mat-toolbar {
    font: inherit;
  }
}

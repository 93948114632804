@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, primary);

  .mat-button-toggle {
    color: mat.get-color-from-palette($primary, 500);
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px mat.get-color-from-palette($primary, 500);
  }

  [dir="rtl"] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: solid 1px mat.get-color-from-palette($primary, 500);
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: solid 1px mat.get-color-from-palette($primary, 500);
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: mat.get-color-from-palette($primary, 800);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard .mat-pseudo-checkbox, 
  .mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox {
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-contrast);
  }
}

@mixin color($theme) {
  .cdk-overlay-container .mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: var(--background-tooltip);
    --mdc-plain-tooltip-supporting-text-color: var(--primary-contrast);
    --mdc-plain-tooltip-supporting-text-size: 10px;
  }

  // Material 15 fix: prevent focus indicator from showing over tooltip 
  button.mdc-icon-button,
  a.mdc-icon-button {
    .mat-mdc-focus-indicator, .mat-mdc-button-touch-target { display: none; }
  }

  .mat-mdc-tab .mdc-tab__text-label .mat-mdc-tooltip-trigger {
    pointer-events: auto;
    cursor: pointer;
  }
}

// TYPOGRAPHY
@mixin typography($theme) {
  .cdk-overlay-container .mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-line-height: 15px;
  }
}
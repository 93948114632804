@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, 'primary');
  $foreground: map.get($config, 'foreground');

  .mat-mdc-slider .mdc-slider__value-indicator{
    background-color: mat.get-color-from-palette($primary, 500);
    .mdc-slider__value-indicator-text { font-size: 11px; }
  }

  .mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: mat.get-color-from-palette($primary, 500);
    --mdc-slider-label-container-color: var(--slider-thumb);
  }

  .mat-mdc-slider .mdc-slider__track--inactive {
    background-color: mat.get-color-from-palette($foreground, 'divider');
  }

  .mat-mdc-slider.bf-inverted-slider  {
    .mdc-slider__track--active {
      background: var(--mdc-slider-active-track-color);
      .mdc-slider__track--active_fill {
        border-color: mat.get-color-from-palette($foreground, 'divider');
      }
    }
  }
}

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, 'foreground');
  $contrast: mat.get-color-from-palette($foreground, text);

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 12px;
    background: rgba($contrast, 0.1);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    border-radius: 9px;
    background: rgba($contrast, 0.5);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($contrast, 0.8);
  }

  /* FIREFOX */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba($contrast, 0.5) rgba($contrast, 0.1);
  }
}

@use 'sass:map';
@use '@angular/material' as mat;
@import 'angular-calendar/scss/angular-calendar.scss';

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, 'background');
  $foreground: map.get($config, 'foreground');
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');

  @include cal-theme(
    (
      weekend-color: mat.get-color-from-palette($primary),
      bg-primary: map.get($background, 'background'),
      bg-secondary: map.get($background, 'background'),
      bg-active: map.get($background, 'hover'),
      border-color: map.get($foreground, 'divider'),
      gray: map.get($background, 'unselected-chip'),
      today-bg: map.get($background, 'hover'),
      event-color-primary: map.get($foreground, 'divider')  // Border on event
    )
  );

  // TOOLTIP
  // Cannot apply style on cal-tooltip because angular-calendar need to find the .cal-tooltip
  .cal-tooltip .mat-tooltip-card{
    background-color: map.get($background, 'card');
    border: 1px solid map.get($foreground, 'divider');
    padding: 8px;
    border-radius: 4px;
    margin: 8px;
  }

  // WEEK
  .cal-week-view {
    // Header
    .cal-day-headers {
      background-color: map.get($background, card);
      z-index: 2;
      position: sticky;
      top: 0;
      // Typography of h5 for day in calendar
      b {
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
        letter-spacing: 0.5px;
      }
      // Typography of mat-caption for month and number in calendar
      span {
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
      }
    }

    ///////////
    // EVENT //
    ///////////

    // Resize handler
    .cal-day-column {
      // Resize top/bottom
      .cal-resize-handle {
        height: 6px;
        width: calc(100% - 4px);
        left: 2px;
        transition: background-color 0.1s ease-out;
      }
      .cal-resize-handle:hover {
        background-color: map.get($foreground, 'divider');
      }
      .cal-resize-handle-before-start {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        top: 2px;
      }
      .cal-resize-handle-after-end {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: 2px;
      }
    }
    // Resize lift/right
    .cal-all-day-events {
      .cal-resize-handle {
        width: 6px;
        height: calc(100% - 2px);
        top: 1px;
        transition: background-color 0.1s ease-out;
      }
      .cal-resize-handle:hover {
        background-color: map.get($foreground, 'divider');
      }
      .cal-resize-handle-before-start {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        left: 3px;
      }
      .cal-resize-handle.cal-resize-handle-after-end {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        right: 3px;
      }
    }

    .cal-event {
      background-color: map.get($background, 'card');
      color: map.get($foreground, 'text');
      border-width: 0;
      padding: 0;

      .event-card {
        border-radius: 4px;
        border: solid 1px map.get($foreground, 'divider');

        &.meeting {
          border-color: mat.get-color-from-palette($primary);
          color: mat.get-color-from-palette($primary);
        }
        &.screening {
          border-width: 0;
        }
      }
    }
  }
}

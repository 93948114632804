@use 'sass:map';
@use '@angular/material' as mat;
@use '../typography' as typography;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $foreground: map.get($config, 'foreground');

  chips-autocomplete mat-chip-option.mat-mdc-standard-chip,
  chips-autocomplete mat-chip.mat-mdc-standard-chip {
    margin: -2px 4px;
  }

  mat-chip-option.mat-mdc-standard-chip,
  mat-chip.mat-mdc-standard-chip {
    color: mat.get-color-from-palette($foreground, icon);
    // Disabled the over animation when the mat chip is only informative
    // that means when we deativate the ripple effect
    &[disableRipple] {
      &::after {
        opacity: 0;
      }
    }
  }

  // Chip for the movie' status on the movie card
  mat-chip-option[selected][disableRipple].mat-primary,
  mat-chip[selected][disableRipple].mat-primary {
    &.movie-chip {
      background-color: rgba(0, 0, 0, 0.288);
      color: #FFFFFF;
    }
  }

  .mat-mdc-standard-chip.warn:not(.mdc-evolution-chip--disabled) {
    background-color: #ffb7b0;
    .mdc-evolution-chip__text-label { color: #FFFFFF; }
  }
  .mat-mdc-standard-chip.mat-mdc-chip-selected.warn { background-color: var(--warn); }
}

@mixin typography($theme) {
  .mat-mdc-chip-listbox .mat-mdc-standard-chip {
    @include mat.typography-level(typography.$bf-typography, 'subtitle-2');
  }
}
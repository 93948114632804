@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');
  $background: map.get($config, 'background');
  $foreground: map.get($config, 'foreground');

  mat-icon,
  .mdc-list-item--with-leading-icon mat-icon.mdc-list-item__start {
    color: mat.get-color-from-palette($foreground, icon);
    path, g, polygon {
      fill: currentColor;
    }
    &.mat-primary {
      color: mat.get-color-from-palette($primary, 500);
      path, g, polygon {
        fill: mat.get-color-from-palette($primary, 500);
      }
    }
    &.mat-accent {
      color: mat.get-color-from-palette($accent, 500);
      path, g, polygon {
        fill: mat.get-color-from-palette($accent, 500);
      }
    }
    &.mat-warn {
      color: mat.get-color-from-palette($warn, 500);
      path, g, polygon {
        fill: mat.get-color-from-palette($warn, 500);
      }
    }
    &[color="disabled"] {
      color: mat.get-color-from-palette($background, disabled-button);
      path, g, polygon {
        fill: mat.get-color-from-palette($background, disabled-button);
      }
    }
  }

  .fullscreen {
    mat-icon {
      path {
        fill: #fff;
      }
    }
  }
}

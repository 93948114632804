@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.get-color-config($theme);

  .mat-datepicker-content .mat-calendar {
    min-height: 380px;
  }
}

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .bf-checkbox {
    width: 100%;
    .mdc-form-field,
    .mdc-form-field label {
      width: 100%;
    }
  }

  .bf-checkbox.mdc-checkbox--disabled {
    pointer-events: auto;
  }
}
